<template>
    <div>
        <div class="modal_header">
            <el-row >
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                           {{ $t('message.update_room') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <el-button :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit()" type="success">{{ $t('message.save_and_close') }}</el-button>
                           <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-form ref="form" :model="form" :rules="rules" class="aticler_m" >
            <el-row :gutter="24">

                <el-col :sm="12" class="mt14">
                    <div class="grid-content">
                        <el-form-item :label="columns.name.title" prop="name">
                            <el-input v-model="form.name" :placeholder="columns.name.title"></el-input>
                        </el-form-item>
                    </div>
                </el-col>
                
                <el-col :sm="12" class="mt14">
                    <div class="grid-content">
                        <el-form-item :label="columns.room_number.title" prop="room_number">
                            <el-input v-model="form.room_number" :placeholder="columns.room_number.title"></el-input>
                        </el-form-item>
                    </div>
                </el-col>

            </el-row>
        </el-form>
    </div>
</template>

<script>

import { mapGetters, mapActions} from "vuex";
import form from '@/utils/mixins/form';
  export default {
      mixins:[form],
    data() {
      return {}
    },
    computed: {
        ...mapGetters({
            rooms: 'rooms/list',
            rules: 'rooms/rules',
            model: 'rooms/model',
            columns: 'rooms/columns'
        })
    },
    watch: {
        model: {
            handler: function () {
                this.loadModel()
            },
            deep: true
        },
    },
    async created() {},
    methods: {
        ...mapActions({
            save: 'rooms/update',
            loadParentPermissions: 'permissions/parent_permissions'
        }),
        submit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then(res => {
                            this.loadingButton = false;
                            this.$alert(res.message);
                            this.parent().listChanged()
                            this.resetForm('form');
                        })
                        .catch(err => {
                            this.loadingButton = false;
                            this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                        });
                }
            });
        },
        loadModel(){
            this.form = JSON.parse( JSON.stringify( this.model ));
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$emit('c-close',{drawer: 'drawerUpdate'});
        }
    },
    components:{
    }
  }
  
</script> 